import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import PageHeader from '../PageHeader';
import data from '../../data/siteDirectory';

import styles from './booksDirectory.module.scss';

const BooksDirectory = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497222290/playTherapyBG.jpg';

  const books = _.map(data.books, (bookView, index) => {
    return (
      <li key={index}>
        <span>
          <Link to={bookView.route}>{bookView.name}</Link>
        </span>
      </li>
    );
  });

  return (
    <main>
      <PageHeader pageName="Books" headerImage={background} />
      <div className={styles.textContainer}>
        <h2 className={styles.header}>Books</h2>
        <ul>{books}</ul>
      </div>
    </main>
  );
};

export default BooksDirectory;
