import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import BooksDirectory from '../../components/BooksDirectory';

export default () => (
  <>
    <SEO
      title="Books | Mindset Family Therapy"
      description="Books from Mindset Family Therapy owner and clinical director Annabella Hagen."
      pathname="/books"
    />
    <Layout>
      <BooksDirectory />
    </Layout>
  </>
);
